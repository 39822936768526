<template>
  <div class="content md flex-box vertical">
    <div class="data-title">
      <div class="title-left flex-box">
        <span>共{{ pagination.total }}条记录</span>
      </div>
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入志工姓名查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.id_card" placeholder="输入志工身份证号查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择注册小组筛选"
            @change="updateList"
            :options="groupOptions"
            v-model="form.group"
            allowClear
            show-search
            option-filter-prop="children"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.content_name" :placeholder="placeholder" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-date-picker
            style="width: 180px;"
            v-model="form.clock_in_date"
            placeholder="选择打卡日期查询"
            :format="format"
            :value-format="format"
            @change="updateList"
            allowClear
          />
        </a-form-model-item>
      </a-form-model>
      <a-space class="title-btn">
        <a-button @click="updateList">查询</a-button>
        <a-button type="primary" @click="exportData" :loading="exporting">
          {{ exporting ? "正在导出" : "批量导出" }}
        </a-button>
        <upload-file
          uploadText="批量导入"
          type="primary"
          url="/admin/volunteer-attendance/import"
          @change="updateList"
          v-if="active == 3"
        ></upload-file>
      </a-space>
    </div>
    <div class="data-tabs">
      <a-tabs v-model="active">
        <a-tab-pane :key="tab.value" :tab="tab.label" v-for="tab in catList"></a-tab-pane>
      </a-tabs>
    </div>
    <div class="data-area">
      <a-table
        ref="dataTable"
        :columns="columns"
        row-key="id"
        :data-source="list"
        :row-selection="{ selectedRowKeys, onChange: onSelectChange, fixed: true }"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ y: h, x: 1500 }"
        @change="handleTableChange"
      >
        <template v-slot:action="text, record">
          <div class="row-btn">
            <a class="txt-btn" @click.stop="editRow(record)">编辑</a>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal
      :maskClosable="false"
      v-model="visible"
      :width="400"
      :confirm-loading="confirmLoading"
      title="编辑考勤记录"
      ok-text="确认"
      cancel-text="取消"
      @ok="confirm"
    >
      <a-form-model class="item-form" ref="editForm" :model="single" :rules="rules" layout="vertical" labelAlign="left">
        <a-form-model-item label="打卡日期" prop="clock_in_date" class="input-item">
          <a-date-picker
            v-model="single.clock_in_date"
            :format="format"
            :value-format="format"
            placeholder="选择日期"
            allowClear
            @change="timeChange"
          />
        </a-form-model-item>
        <a-form-model-item label="签到时间" prop="sign_in_time" class="input-item">
          <a-time-picker
            v-model="single.sign_in_time"
            :format="timeFormat"
            :value-format="timeFormat"
            placeholder="选择时间"
            allowClear
            @change="timeChange"
          />
        </a-form-model-item>
        <a-form-model-item label="签退时间" prop="sign_out_time" class="input-item">
          <a-time-picker
            v-model="single.sign_out_time"
            :format="timeFormat"
            :value-format="timeFormat"
            placeholder="选择时间"
            allowClear
            @change="timeChange"
          />
        </a-form-model-item>
        <a-form-model-item label="出勤时长" prop="duration">
          <a-input-number
            v-model="single.duration"
            placeholder="请输入数字"
            :min="0"
            :step="0.01"
            allowClear
          ></a-input-number>
          <span> 小时 </span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { volunStatusOptions } from "../../../common/hr/volunteer";
import { getTemple } from "../../../common/js/storage";
import {
  clone,
  dataConvertOptions,
  downLoadBlobFile,
  getKeyTitle,
  getQuery,
  getTempleId,
  stringConvertTimestamp,
  timestampConvertString,
} from "../../../common/js/tool";

const catMap = {
  3: { text: "服务", typeKey: "serviceType" },
  1: { text: "活动", typeKey: "activityType" },
  2: { text: "培训", typeKey: "trainType" },
};

export default {
  name: "Assessment",
  data() {
    return {
      format: "YYYY-MM-DD",
      timeFormat: "HH:mm:ss",
      loading: false,
      list: [],
      form: {},
      h: 500,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      active: 3,
      groupOptions: [],
      selectedRowKeys: [],
      searchKeyType: {
        name: 2,
        id_card: 2,
        group: 2,
        content_name: 2,
        clock_in_date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            value = stringConvertTimestamp(value, "yyyy-MM-dd");
            res = `&${key}=${value}`;
          }
          return res;
        },
      },
      exporting: false,
      importimg: false,
      visible: false,
      confirmLoading: false,
      single: {},
      rules: {
        clock_in_date: [{ required: true, message: "请选择签到日期", trigger: "change" }],
        sign_in_time: [{ required: true, message: "请选择签到时间", trigger: "change" }],
        sign_out_time: [{ required: true, message: "请选择签退时间", trigger: "change" }],
        duration: [{ required: true, message: "请输入出勤时长", trigger: "blur" }],
      },
    };
  },
  computed: {
    placeholder() {
      let catName = catMap[this.active]?.text;
      let placeholder = `输入${catName}名称查询`;
      return placeholder;
    },
    columns() {
      let catName = catMap[this.active]?.text;
      let typeKey = catMap[this.active]?.typeKey;

      let columns = [
        { title: "打卡日期", dataIndex: "clock_in_date", width: 120 },
        {
          title: "志工姓名",
          width: 100,
          customRender: (text) => {
            return <span>{text.enrollList?.volunteer?.name || "-"}</span>;
          },
        },
        {
          title: "身份证号",
          width: 180,
          customRender: (text) => {
            return <span>{text.enrollList?.volunteer?.id_card || "-"}</span>;
          },
        },
        {
          title: "志工状态",
          width: 100,
          customRender: (text) => {
            return (
              <span>
                {getKeyTitle(volunStatusOptions, text.enrollList?.volunteer?.register_status, "value", "label")}
              </span>
            );
          },
        },
        {
          title: "注册小组",
          width: 120,
          customRender: (text) => {
            return <span>{text.enrollList?.volunteer?.volunteerGroup?.name || "-"}</span>;
          },
        },
        // { title: "考勤小组", dataIndex: "att_group" },
        {
          title: "考勤类别",
          width: 100,
          customRender: () => {
            return <span>志工{catName}</span>;
          },
        },
        {
          title: "类型",
          width: 100,
          customRender: (text) => {
            return <span>{text.enrollList?.content?.[typeKey]?.name || "-"}</span>;
          },
        },
        {
          title: "名称",
          width: 180,
          customRender: (text) => {
            return <span>{text.enrollList?.content?.name || "-"}</span>;
          },
        },
        { title: "签到时间", dataIndex: "sign_in_time", width: 180 },
        { title: "签退时间", dataIndex: "sign_out_time", width: 180 },
        { title: "出勤时长", dataIndex: "duration", width: 100 },
        { title: "操作", key: "action", fixed: "right", scopedSlots: { customRender: "action" } },
      ];
      columns[6].title = catName + columns[6].title;
      columns[7].title = catName + columns[7].title;
      return columns;
    },
    course_id() {
      return this.$route.query.course_id;
    },
    catList() {
      let options = [
        {
          label: "服务",
          value: 3,
        },
        {
          label: "活动",
          value: 1,
        },
        {
          label: "培训",
          value: 2,
        },
      ];
      if (this.course_id) {
        options.splice(0, 2);
      }
      return options;
    },
  },
  created() {
    if (this.course_id) {
      // 培训
      this.active = 2;
    }
    this.$watch("active", () => {
      this.selectedRowKeys = [];
      this.updateList();
    });
    this.getGroup();
    // this.getCondition();
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    getCondition() {
      let course_id = this.$route.query.course_id;
      if (course_id) {
        this.form.course_id = course_id;
      }
    },
    onSelectChange(selectedRowKeys) {
      // console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    timeChange() {
      let date = this.single.clock_in_date;
      let start = this.single.sign_in_time;
      let end = this.single.sign_out_time;
      if (date && start && end) {
        let start_time = new Date(`${date} ${start}`).getTime();
        let end_time = new Date(`${date} ${end}`).getTime();
        let duration = ((end_time - start_time) / 1000 / 60 / 60).toFixed(2);
        this.$set(this.single, "duration", duration);
      }
    },
    getGroup() {
      return this.$axios({
        url: "/admin/volunteer-group",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.groupOptions = dataConvertOptions(res.data);
      });
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    dealList(list) {
      list.forEach((item) => {
        let { clock_in_date, sign_in_time, sign_out_time } = item;
        if (clock_in_date) {
          item.clock_in_date = timestampConvertString(clock_in_date, "yyyy-MM-dd");
        }
        if (sign_in_time) {
          item.sign_in_time = sign_in_time.split(" ")[1];
        }
        if (sign_out_time) {
          item.sign_out_time = sign_out_time.split(" ")[1];
        }
      });
      return list;
    },
    getList() {
      this.loading = true;
      let temple = getTemple();
      let url = `/admin/volunteer-attendance?sort=-id&expand=enrollList&type=${this.active}`;
      if (temple) {
        url += `&filter[volunteer_attendance.temple_id]=${temple}`;
      }
      if (this.course_id) {
        url += `&course_id=${this.course_id}`;
      }
      let { current, pageSize } = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      url += getQuery.call(this, this.searchKeyType);
      this.$axios
        .get(url, { noTempleFilter: true })
        .then((res) => {
          this.loading = false;
          let list = this.dealList(res.data);
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let searchKeyType = {
        name: 2,
        id_card: 2,
        group: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&volunteer_group=${value}`;
          }
          return res;
        },
        content_name: 2,
        clock_in_date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            value = stringConvertTimestamp(value, "yyyy-MM-dd");
            res = `&${key}=${value}`;
          }
          return res;
        },
      };
      let url = `/admin/volunteer-attendance/export?type=${this.active}`;
      if (this.selectedRowKeys.length > 0) {
        url += `&ids=${this.selectedRowKeys.join(",")}`;
      }
      if (this.course_id) {
        url += `&course_id=${this.course_id}`;
      }
      url += getQuery.call(this, searchKeyType);
      url += getTempleId();
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, "考勤数据");
        })
        .finally(() => {
          this.exporting = false;
        });
    },
    editRow(record) {
      this.visible = true;
      this.single = clone(record);
    },
    confirm() {
      this.confirmLoading = true;
      this.$refs.editForm.validate((flag) => {
        if (flag) {
          let form = clone(this.single);
          if (form.sign_in_time) {
            form.sign_in_time = `${form.clock_in_date} ${form.sign_in_time}`;
          }
          if (form.sign_out_time) {
            form.sign_out_time = `${form.clock_in_date} ${form.sign_out_time}`;
          }
          if (form.clock_in_date) {
            form.clock_in_date = stringConvertTimestamp(form.clock_in_date);
          }

          this.$axios({
            url: `/admin/volunteer-attendance/${form.id}`,
            method: "PATCH",
            data: form,
          }).then(() => {
            this.getList();
            this.confirmLoading = false;
            this.$message.success("编辑成功");
            this.visible = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.data-tabs {
  text-align: center;
}
.data-area {
  margin-top: 0;
}
</style>
