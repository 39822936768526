<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    :query="query"
    entity-name="核销人"
    add-btn="新增核销人"
    hide-show-detail
    show-row-num
    :deal-list="dealList"
    :before-update="beforeUpdate"
    :rules="rules"
    :columns="columns"
    :search-key-type="searchKeyType"
  >
    <template v-slot:query="{ form }">
      <!-- <a-form-model-item label="手机号码">
        <a-input v-model="form.mobile" placeholder="输入手机号码查询" allowClear></a-input>
      </a-form-model-item> -->
      <a-form-model-item label="核销类别">
        <a-select
          v-model="form.privilege"
          placeholder="选择核销类别"
          :options="typeOptions"
          allowClear
          @change="typeChange"
          style="width: 180px;"
        ></a-select>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="核销人" prop="user_id">
        <select-book type="user" v-model="form.user_id"></select-book>
      </a-form-model-item>
      <!-- <a-form-model-item label="手机号码" prop="mobile">
        <a-input v-model="form.mobile" placeholder="输入手机号码" allowClear></a-input>
      </a-form-model-item> -->
      <a-form-model-item label="核销类别" prop="privilege">
        <a-select placeholder="选择核销类别" :options="typeOptions" v-model="form.privilege" allowClear></a-select>
      </a-form-model-item>
    </template>
  </data-list>
</template>
<script>
// import { isEmpty } from "../../../common/js/tool";
import { getSystemPermissionOptions, getSystemRoleName } from "../../../permission/subsystem";

let typeOptions = getSystemPermissionOptions(2).slice(2, 8);
const default_query = "&sort=-id&filter[system]=2&filter[privilege][gt]=2&filter[privilege][lt]=9";

export default {
  name: "AttVerifiedBy",
  data() {
    return {
      url: "/admin/user-role",
      searchKeyType: {
        // mobile: function(form, key) {
        //   let value = form[key];
        //   let res = "";
        //   if (!isEmpty(value)) {
        //     res = `&filter[${key}][fis]=${value}`;
        //   }
        //   return res;
        // },
      },
      columns: [
        {
          title: "核销人",
          dataIndex: "user_id",
          customRender: (text) => {
            return <open-data type="userName" openid={text[0]} />;
          },
        },
        // { title: "手机号码", dataIndex: "mobile" },
        {
          title: "核销类别",
          dataIndex: "roleText",
        },
      ],
      rules: {
        user_id: [{ required: true, message: "请选择核销人", trigger: "change" }],
        // mobile: [
        //   { required: true, message: "请输入手机号码", trigger: "blur" },
        //   { pattern: "^1[3-9]\\d{9}$", message: "请输入正确的手机号码" },
        // ],
        privilege: [{ required: true, message: "请选择核销类别", trigger: "change" }],
      },
      typeOptions,
      query: default_query,
    };
  },
  computed: {
    dataList() {
      return this.$refs.dataList;
    },
  },
  methods: {
    dealList(list, fn) {
      list?.forEach((item) => {
        if (item.user_id) {
          item.user_id = JSON.parse(item.user_id);
        }
        item.roleText = getSystemRoleName(item.system, item.privilege);
      });
      fn(list);
    },
    beforeUpdate(form, fn) {
      if (!form.id) {
        form.system = 2;
      }
      form.user_id = JSON.stringify(form.user_id);
      fn(form);
    },
    updateList() {
      this.dataList.updateList();
    },
    typeChange(val) {
      let query = default_query;
      if (val) {
        query = `&sort=-id&filter[system]=2&filter[privilege]=${val}`;
      }
      this.query = query;
    },
  },
};
</script>

<style lang="less" scoped></style>
